[type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
   opacity: 0;
   position: absolute;
}

.form-check {
    cursor: pointer;
}

.form-check-label {
    position: relative;
}

.form-check-label:before, .form-check-label-disabled:before {
    transform-origin: 100% 100%;
    -webkit-transform-origin: 100% 100%;
    position: absolute;
    left: 0;
    border-color: #4bac61;
}

[type="checkbox"]:not(:checked) + .form-check-label:before,
[type="checkbox"]:not(:checked) + .form-check-label-disabled:before {
    content: "";
    display: inline-block;
    border: 2px solid #8a8a8a;
    width: 18px;
    height: 18px;
    border-radius: 1px;
    transition: 0.2s ease all;
    top: 50%;
    transform: translateY(-50%);
}

[type="checkbox"]:checked + .form-check-label:before,
[type="checkbox"]:checked + .form-check-label-disabled:before {
    content: "";
    display: inline-block;
    border: 2px solid #4bac61;
    width: 18px;
    height: 18px;
    border-radius: 1px;
    transition: 0.2s ease all;
    border-style: none solid solid none;
    transform: rotate(40deg);
    -webkit-transform: rotate(40deg);
    width: 12px;
    height: 1.375rem;
    top: -4px;
    left: 0px;
}

.form-check-label, .form-check-label-disabled {
    position: relative;
    display: inline-block;
    height: 1.5625rem;
    padding-left: 30px;
    line-height: 1.5625rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: rgba(0,0,0,0.8);
}

[type="checkbox"]:not(:checked):focus + .form-check-label:before {
    box-shadow: 0 0 0 .2rem rgba(170,255,128,0.75);
    -webkit-box-shadow: 0 0 0 .2rem rgba(170,255,128,0.75);
    -moz-box-shadow: 0 0 0 .2rem rgba(170,255,128,0.75);
    border-color: #4bac61;
}

[type="checkbox"]:checked:focus + .form-check-label:before {
    -moz-box-shadow: 2px 2px 0px #aaff80;
    -webkit-box-shadow: 2px 2px 0px #aaff80;
    box-shadow: 2px 2px 0px #aaff80;
}

/** When disabled **/

form-check-label-disabled {
    position: relative;
    color: #6c757d;
}

.form-check-label-disabled:before {
    border-color: #8a8a8a;
    background-color: #BDBDBD;
}

[type="checkbox"]:checked + .form-check-label-disabled:before {
    border: 2px solid #BDBDBD;
    border-style: none solid solid none;
    background-color: inherit;
}

.form-check-label-disabled {
    cursor: default;
    color:#BDBDBD;
}