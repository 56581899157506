.inputGroup:hover .inputView, .IntlinputGroup:hover .inputView, .IntlinputGroup:hover .langSelect, inputGroup:hover .langSelect{
    border: 1px solid rgba(127,127,127, 0.5);
}

.lb-0 .IntlinputGroup:hover .inputView {
    border-left: 0px;
}

.inputGroup:hover .penBox, .IntlinputGroup:hover .penBox, .inputBox:focus .penBox {
    border: 1px solid rgba(0,0,0, .9);
}

.decorated-vScrollbar::-webkit-scrollbar {
    width: 5px;
}
 
.decorated-vScrollbar::-webkit-scrollbar-track {
    top: 0;
    background-color: white;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.decorated-vScrollbar::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 15px;
    background: rgba(127, 127, 127, 0.651); 
}