.form-group {
    transition: all 0.3s;
    position: relative;
}

.form-label {
    flex-shrink: 0;
    font-size: 1em;
    color: #aaa;
    display:block;
    opacity: 1;
    transform: translateY(-50%);
    transform-origin: top left;
    transition: all 0.3s;
    position: absolute;
    top: 50%;
}

.form-control::placeholder {
    color: transparent;
}

.form-control:focus + .form-label,
.form-control:not(:placeholder-shown) + .form-label {
    transform: translateY(-1.75em) scale(0.75);
    color: #3182ce;
}