 .frosted-glass {
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(4px);
    background-color: rgba(243,243,243,.8);
 }   

.blackhover {
   color: #666;
}

.blackhover:hover {
   color: #000;
   transition: color 0.2s linear;
}

.expandBorderFromCenter {
     position: relative;
     display: inline-block;
   }
   
.expandBorderFromCenter:after {
   position: absolute;
   left: 50%;
   content: '';
   height: 2px;
   background: #4bac61;
   transition: all 0.2s linear;
   width: 0;
   bottom: 0;  
}

.expandBorderFromCenter:hover:after {
   width: 100%;
   margin-left: -50%;
}
