.frostedGlass {
    -webkit-backdrop-filter: saturate(180%) blur(5px);
    backdrop-filter: saturate(180%) blur(4px);
    background-color: rgba(75, 172, 97, 0.8);
}

.normal {
    background-color: rgb(75, 172, 97);
}

.tab {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-shrink: 0;
    cursor: pointer;
}

.tab a {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: var(--chakra-space-3);
    padding-right: var(--chakra-space-3);
}

.selected {
    position: relative;
}

.selected::after {
    position: absolute;
    content: "";
    height: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFF;
    width: inherit;
}

.ripple {
    background-position: center;
    transition: background 0.4s;
}
.ripple:hover {
    background: #4BAC61 radial-gradient(circle, transparent 1%, #54c46e 1%) center/15000%;
}
  
.ripple:active {
    background-color: #4BAC61;
    background-size: 100%;
    transition: background 0s;
}

.noScrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Opera, Safari*/
}

.noScrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.decorated-scrollbar::-webkit-scrollbar {
    height: 5px;
}
 
.decorated-scrollbar::-webkit-scrollbar-track {
    top: 0;
    background-color: #4bac61;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.decorated-scrollbar::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 15px;
    background: rgba(44, 122, 62, 0.9); 
}
