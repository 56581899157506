aside {
    display: flex;
    flex-direction: column;
    border: 1px solid #ffe79e;
    border-radius: 3px;
    box-shadow: 0 1px 2px hsla(0,0%,0%,0.05),0 1px 4px hsla(0,0%,0%,0.05),0 2px 8px hsla(0,0%,0%,0.05);
    width: 300px;
    height: 350px;
    margin-top: 5vh;
    margin-left: 60px;
}

header {
    background-color: #FDF3D5;
    border: 1px solid #ffe79e;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 16px;
    color: #3d4247;
    display: flex;
}

main {
    display: flex !important;
    flex-direction: column;
    background-color: #FDF7E2;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    
}