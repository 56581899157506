.rc-input-number-handler-up, .rc-input-number-handler-down {
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.decorated-vScrollbar::-webkit-scrollbar {
    width: 5px;
}
 
.decorated-vScrollbar::-webkit-scrollbar-track {
    top: 0;
    background-color: white;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.decorated-vScrollbar::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 15px;
    background: rgba(127, 127, 127, 0.651); 
}

.noScrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Opera, Safari*/
}

.noScrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}