.dropdown.show {
    overflow: initial;
    opacity: 1;
    max-height: 100vh;
    transition: .3s ease all;
}

.dropdown {
    overflow: hidden;
    max-height: 0px;
    opacity: 0;
    transition: .3s ease all;
}

.standard-initial-padding {
    padding-inline-start: 0.5rem !important;
    -webkit-padding-start: 0.5rem !important;
    padding-inline-end: 0.5rem !important;
    -webkit-padding-end: 0.5rem !important;
}