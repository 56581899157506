.bg-overlay {
    position: relative;
}

.bg-overlay::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: "";
}

.overlay-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blurry {
    filter: blur(1.5px);
}

.barred-form-text {
    display: flex;
    flex-direction: row;  
}

.barred-form-text:before, .barred-form-text:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}

.barred-form-text:before {
    margin-right: 10px
}
  
.barred-form-text:after {
    margin-left: 10px
}
  
  
form {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}