input[type="file"].uploadImageBtn {
    display: none;
}

.custom-file-upload {
    border: none;
}

.custom-file-upload:hover {
    background-color: #edf2f7;
    transition: background-color 0.3s linear;
}

.custom-file-upload:active {
    background-color: #e2e8f0;
    box-shadow: 0 0 0pt 1.5pt #8ec2ed;
}

.custom-file-upload:focus {
    box-shadow: 0 0 0pt 1.5pt #8ec2ed;
}

.custom-file-upload {
    display: flex;
    cursor: pointer;
    outline: none;
    transition: box-shadow 0.3s linear;
    box-shadow: 0 0 0 0pt grey;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.dashed {
    border-radius: 0.5rem;  
    border: 1px solid rgba(0,0,0, .3);
    border-style: dashed;
}

.darken-on-hover {
    transition: .3s ease all;
    color: #666;
}

.darken-on-hover:hover {
    color: #1a2020;
}

.blurBackground {
    position: relative;
    height: auto;
    background-attachment: "contain";
}

.blurBackground:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(3px);
}