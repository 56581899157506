.transition-gradient {
    background: linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%);
    position: relative;
}

.transition-gradient::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    transition: .3s ease all !important;
    background-color: rgba(6,94,43,1);
}

.transition-gradient:hover::before {
    opacity: 1;
}
